import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import * as styles from "./onboarding.module.scss";
import BackButton from "../components/back-button/back-button";

export interface ManualOnboardingPageModel {
  title: string;
  _rawManualDescription: any;
}

export const query = graphql`
  query ManualOnboardingPageQuery {
    site: sanityOnboardingPage {
      _rawManualDescription
      title
    }
  }
`;

const ManualOnboardingPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: ManualOnboardingPageModel = (data || {}).site;

  return (
    <Layout>
      <SEO title={`${site.title} - Manual Onboarding`} />
      <Container role="main">
        <div>
          <BackButton onClick={() => window.history.back()} />
          <h1>{site.title} - Manual Onboarding</h1>
          <div className={styles.descriptionContainer}>
            <BlockContent blocks={site._rawManualDescription} />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ManualOnboardingPage;
